<template>
  <div class="flex items-center flex-wrap gap-2">
    <button
      v-if="page > 1"
      type="button"
      class="btn btn-gray"
      :disabled="isLoading"
      @click="navigate(1)"
    >
      &lt;&lt;&lt;
    </button>
    <button
      type="button"
      class="btn btn-gray"
      :disabled="isLoading || page < 2"
      @click="prev"
    >
      Prev
    </button>
    <div class="px-5">
      {{ count + (page - 1) * limit }} {{ total > 1 ? `of ${total}` : '' }}
    </div>
    <button
      type="button"
      class="btn btn-gray"
      :disabled="isLoading || (pages < 2 && page <= pages)"
      @click="next"
    >
      Next
    </button>
    <button
      v-if="pages > 2"
      type="button"
      class="btn btn-gray"
      :disabled="isLoading"
      @click="navigate(pages)"
    >
      &gt;&gt;&gt;
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    isLoading: Boolean,
    meta: Object,
    value: Number
  },
  data() {
    return {};
  },
  computed: {
    count() {
      return this.meta.chunk_count || 1;
    },
    limit() {
      return this.meta.limit || 1;
    },
    page() {
      return this.meta.page || 1;
    },
    pages() {
      return this.meta.page_count || 1;
    },
    total() {
      return this.meta.total_count || 0;
    },
  },
  mounted() {},
  methods: {
    navigate(page) {
      this.$emit('change', page);
    },
    next() {
      this.$emit('change', this.page + 1);
    },
    prev() {
      this.$emit('change', this.page - 1);
    },
    reset() {
      this.$emit('change', 1);
    },
  },
};
</script>
